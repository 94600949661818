import { GetStaticProps } from 'next';
import { PassengersPageId } from '../../features/app/pages/domain/constants/PageId';
import { getStaticPathsForLocales } from '../../features/app/pages/domain/use-cases/getStaticPathsForLocales';
import { createGetStaticPropsWithPage } from '../../features/app/root/domain/use-cases/createGetStaticPropsWithPage';
import { getFlightsEnrichmentData } from '../../features/flights/shared/flight/domain/use-cases/getFlightsEnrichmentData';
import { IHomePageData } from '../../features/home/domain/models/IHomePageData';
import { getHomeHeaderImages } from '../../features/home/domain/use-cases/getHomeHeaderImages';
import { HomePage } from '../../features/home/ui/HomePage';
import { defaultLocale as locale } from '../../features/shared/i18n/locale/constants/default';

export const getStaticPaths = getStaticPathsForLocales;

export const getStaticProps: GetStaticProps<IHomePageData> =
	createGetStaticPropsWithPage(PassengersPageId.Home, {
		headerImages: () => getHomeHeaderImages(),
		flightsEnrichmentData: () => getFlightsEnrichmentData(locale),
	});

export default HomePage;
