import React from 'react';
import styled from '@emotion/styled';
import { ITheme, Shimmer } from '../../../../ui';
import { Shortcut } from './Shortcut';
import { StyledShortcuts } from './StyledShortcuts';
import { IShortcut } from './types';
import {
	StyledFlexShortcutGrid,
	StyledFlexShortcutItem,
} from './StyledFlexShortcutGrid';
import { StyledShortcut } from './StyledShortcut';

interface IShortcutsMainProps {
	shortcuts: IShortcut[];
}
export const ShortcutsGridContainer = ({ shortcuts }: IShortcutsMainProps) => (
	<StyledShortcuts>
		<StyledFlexShortcutGrid
			columns={[
				Math.max(Math.min(4, shortcuts?.length ?? 0), 3),
				{
					tablet: 2,
					mobile: 2,
				},
			]}
			number={shortcuts.length}
		>
			{shortcuts.map(({ href, icon, title, isEnabled, color, isNew }) => (
				<StyledFlexShortcutItem key={href} number={shortcuts.length}>
					{!isEnabled ? (
						<Shimmer aria-label="shimmer" style={{ flexGrow: 1 }}>
							<StyledShortcut href={href}>
								<StyledShortcutShimmer />
							</StyledShortcut>
						</Shimmer>
					) : (
						<Shortcut {...{ href, icon, color, title, isNew }} />
					)}
				</StyledFlexShortcutItem>
			))}
		</StyledFlexShortcutGrid>
	</StyledShortcuts>
);

const StyledShortcutShimmer = styled.div<{}, ITheme>`
	width: 100%;
`;
