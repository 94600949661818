import React from 'react';
import {
	airportMap,
	busWaitTime,
	mobileOrderingIcon,
	parkingAvailabilityIcon,
	parkingIcon,
	securityIcon,
} from './icons';
import royaltyProgramIcon from '../../../../../assets/loyalty/royalty-program.svg?reference';
import accountIcon from '../../../../../assets/account-white.svg?reference';

import { useRemoteConfigValue } from '../../../shared/remote-config/ui/useRemoteConfigValue';
import { ShortcutsGridContainer } from './ShortcutsGridContainer';
import { PassengersPageId } from '../../../app/pages/domain/constants/PageId';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';
import { IShortcut } from './types/IShortcut';
import { useLocale } from '../../../shared/i18n/locale/useLocale';
import { getMapUrlForPoiId } from '../../../shop-dine-and-relax/ui/detail/helpers/getMapUrlForPoiId';
import { SIGN_UP_DIALOG_HASH } from '../../../auth/sign-up/ui/SignUpDialog';
import { Color } from '../../../shared/styles/Color';

interface IShortcutsProps {
	isAuthenticated: boolean;
}
export const Shortcuts = ({ isAuthenticated }: IShortcutsProps) => {
	const shortcuts = useShortcuts(isAuthenticated);

	return <ShortcutsGridContainer shortcuts={shortcuts} />;
};

function useShortcuts(isAuthenticated: boolean): IShortcut[] {
	const { t } = useTranslation();

	const accountOverviewUrl = useUrlForPageId(PassengersPageId.AccountOverview);
	const royaltyProgramUrl = useUrlForPageId(PassengersPageId.RoyaltyProgram);
	const parkingUrl = useUrlForPageId(PassengersPageId.Parking);
	const parkingAvailabilityUrl = useUrlForPageId(
		PassengersPageId.ParkingAvailability,
	);
	const busWaitTimesUrl = useUrlForPageId(PassengersPageId.BusWaitTimes);
	const securityWaitingTimesUrl = useUrlForPageId(
		PassengersPageId.SecurityWaitingTimes,
	);
	const mobileOrderingUrl = useUrlForPageId(
		PassengersPageId.ShopDineAndRelax,
		'filters=mobile-ordering',
	);
	const airportMapUrl = getMapUrlForPoiId(useLocale());

	const { value: isParkingAvailabilityEnabled } = useRemoteConfigValue(
		'parking_availability_enabled',
	);
	const { value: isBusWaitTimesEnabled } = useRemoteConfigValue(
		'bus_wait_times_enabled',
	);
	const { value: isSecurityWaitTimesEnabled } = useRemoteConfigValue(
		'security_wait_times_enabled',
	);
	const { value: isMobileOrderingEnabled } = useRemoteConfigValue(
		'mobile_ordering_enabled',
	);

	const shortcuts = [];

	if (isAuthenticated) {
		shortcuts.push({
			href: accountOverviewUrl,
			icon: accountIcon,
			title: t('web_home_shortcut_my_clt_account_title'),
			isEnabled: true,
			color: Color.PrimaryDark,
			isNew: true,
		});
	} else {
		shortcuts.push({
			href: SIGN_UP_DIALOG_HASH,
			icon: accountIcon,
			title: t('user_create_account_title'),
			isEnabled: true,
			color: Color.PrimaryDark,
			isNew: true,
		});
	}

	shortcuts.push(
		{
			href: royaltyProgramUrl,
			icon: royaltyProgramIcon,
			title: t('loyalty_royalty_program_label'),
			isEnabled: true,
			color: Color.AccentDefault,
			isNew: true,
		},
		{
			href: parkingUrl,
			icon: parkingIcon,
			title: t('web_home_shortcut_parking_reservation_title'),
			isEnabled: true,
			color: Color.SecondaryDefault,
		},
	);

	if (isBoolean(isBusWaitTimesEnabled) && isBusWaitTimesEnabled) {
		shortcuts.push({
			href: busWaitTimesUrl,
			icon: busWaitTime,
			title: t('web_home_shortcut_bus_waiting_times_title'),
			isEnabled: true,
			color: Color.PrimaryDark,
		});
	} else if (isUndefined(isBusWaitTimesEnabled)) {
		shortcuts.push({
			href: busWaitTimesUrl,
			icon: busWaitTime,
			title: t('web_home_shortcut_bus_waiting_times_title'),
			isEnabled: false,
			color: Color.PrimaryDark,
		});
	}

	shortcuts.push({
		href: airportMapUrl,
		icon: airportMap,
		title: t('web_home_shortcut_airport_map_title'),
		isEnabled: true,
		color: Color.TertiaryDefault,
	});

	if (isBoolean(isParkingAvailabilityEnabled) && isParkingAvailabilityEnabled) {
		shortcuts.push({
			href: parkingAvailabilityUrl,
			icon: parkingAvailabilityIcon,
			title: t('web_parking_availability_results_back_button'),
			isEnabled: true,
			color: Color.TertiaryDefault,
		});
	} else if (isUndefined(isParkingAvailabilityEnabled)) {
		shortcuts.push({
			href: parkingAvailabilityUrl,
			icon: parkingAvailabilityIcon,
			title: t('web_parking_availability_results_back_button'),
			isEnabled: false,
			color: Color.TertiaryDefault,
		});
	}

	if (isBoolean(isSecurityWaitTimesEnabled) && isSecurityWaitTimesEnabled) {
		shortcuts.push({
			href: securityWaitingTimesUrl,
			icon: securityIcon,
			title: t('web_home_shortcut_security_title'),
			isEnabled: true,
			color: Color.AccentDefault,
		});
	} else if (isUndefined(isSecurityWaitTimesEnabled)) {
		shortcuts.push({
			href: securityWaitingTimesUrl,
			icon: securityIcon,
			title: t('web_home_shortcut_security_title'),
			isEnabled: false,
			color: Color.AccentDefault,
		});
	}

	if (isBoolean(isMobileOrderingEnabled) && isMobileOrderingEnabled) {
		shortcuts.push({
			href: mobileOrderingUrl,
			icon: mobileOrderingIcon,
			title: t('web_home_shortcut_ordering_food_title'),
			isEnabled: true,
			color: Color.SecondaryDefault,
		});
	} else if (isUndefined(isMobileOrderingEnabled)) {
		shortcuts.push({
			href: mobileOrderingUrl,
			icon: mobileOrderingIcon,
			title: t('web_home_shortcut_ordering_food_title'),
			isEnabled: false,
			color: Color.SecondaryDefault,
		});
	}

	return shortcuts;
}

function isBoolean(shortcutEnabled: boolean | undefined) {
	return typeof shortcutEnabled === 'boolean';
}
function isUndefined(shortcutEnabled: boolean | undefined) {
	return typeof shortcutEnabled === 'undefined';
}
