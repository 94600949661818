import React, { Component, KeyboardEvent } from 'react';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Grid, GridItem } from '../../../../ui';
import { FlightSearchWrap } from './FlightSearchWrap';
import { FlightSearchHighlight } from './FlightSearchHighlight';
import { IStores } from '../../../app/root/domain/stores/createStores';
import { FlightListStore } from '../../../flights/overview/domain/stores';
import { IFlightSearchSuggestion } from '../../../flights/search/domain/models/IFlightSearchSuggestion';
import { SuggestionStore } from '../../../flights/search/domain/stores/SuggestionStore';
import {
	FlightDateSelect,
	ISelectDay,
} from '../../../flights/search/ui/FlightDateSelect';
import { FlightSearchBar } from '../../../flights/search/ui/FlightSearchBar';
import {
	trackDateSelectChange,
	trackQueryChange,
	trackSuggestionSelect,
} from '../../../flights/shared/analytics';
import { II18nProps, withTranslate } from '../../../shared/i18n/translate';
import {
	ISiteMapProps,
	withSiteMap,
} from '../../../app/pages/ui/utils/withSiteMap';
import Responsive from '../../../../ui/utils/Responsive';

interface IProps {
	navigateToFlightList: () => void;
}

interface IInjectedProps extends IProps, II18nProps, ISiteMapProps {
	flightListStore: FlightListStore;
	flightSuggestionStore: SuggestionStore<IFlightSearchSuggestion>;
}

interface IState {
	shouldResetSearch: boolean;
	isMounted: boolean;
}

export const FlightSearchWithI18n = inject(
	({ flightListStore, flightSuggestionStore }: IStores) => ({
		flightListStore,
		flightSuggestionStore,
	}),
)(
	observer(
		class FlightSearchWithI18n extends Component<
			IProps & II18nProps & ISiteMapProps,
			IState
		> {
			constructor(props: IProps & II18nProps & ISiteMapProps) {
				super(props);

				makeObservable(this, {
					componentDidMount: computed,
				});
			}

			public get injected() {
				return this.props as IInjectedProps;
			}

			public state: IState = {
				shouldResetSearch: true,
				isMounted: false,
			};

			public componentDidMount() {
				const { flightListStore, flightSuggestionStore } = this.injected;

				flightListStore.reset();
				flightSuggestionStore.reset();

				flightListStore.loadDay();

				this.setState({ isMounted: true });
			}

			public componentWillUnmount() {
				const { shouldResetSearch } = this.state;
				const { flightListStore, flightSuggestionStore } = this.injected;

				if (shouldResetSearch) {
					flightListStore.reset();
					flightSuggestionStore.reset();
				}
			}

			protected onChangeDate = (selected: ISelectDay | null) => {
				const { flightListStore } = this.injected;

				if (!selected) {
					return;
				}

				if (flightListStore.selectedDay.isSame(selected.date, 'day')) {
					return;
				}

				flightListStore.loadDay(selected?.date);

				trackDateSelectChange(selected);
			};

			protected onQuerySubmit = (query: string) => {
				this.injected.flightListStore.setQuery(query);

				trackQueryChange(query);
			};

			protected onSelectFlightSuggestion = (
				selectedItem: IFlightSearchSuggestion | null,
			) => {
				if (selectedItem) {
					this.navigateToFlightList();
				}

				trackSuggestionSelect(selectedItem);
			};

			protected onClickSearch = () => {
				this.navigateToFlightList();
			};

			protected onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
				if (event.key === 'Enter') {
					this.navigateToFlightList();
				}
			};

			private navigateToFlightList() {
				this.setState({
					shouldResetSearch: false,
				});
				const { navigateToFlightList } = this.props;

				navigateToFlightList();
			}

			public render() {
				const { t } = this.injected;
				const { isMounted } = this.state;

				return (
					<FlightSearchWrap>
						<FlightSearchHighlight>
							<h2>{t('dashboard_search_header')}</h2>

							<StyledGrid>
								<GridItem columns={[4, { mobileXL: 12 }]}>
									<FlightDateSelect
										onChange={this.onChangeDate}
										disabled={!isMounted}
									/>
								</GridItem>

								<GridItem columns={[8, { mobileXL: 12 }]}>
									<FlightSearchBar
										onQuerySubmit={this.onQuerySubmit}
										onSelectItem={this.onSelectFlightSuggestion}
										onClickSearch={this.onClickSearch}
										onKeyUp={this.onKeyUp}
										disabled={!isMounted}
									/>
								</GridItem>
							</StyledGrid>
						</FlightSearchHighlight>
					</FlightSearchWrap>
				);
			}
		},
	),
);

const StyledGrid = styled(Grid)`
	flex-wrap: nowrap;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
	}
`;

export const FlightSearch = withSiteMap<IProps>(
	withTranslate(FlightSearchWithI18n),
);
