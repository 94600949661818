import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { PageLayout } from '../../shared/layout/page/PageLayout';
import {
	getOverlapWithImagePageHeaderStyles,
	ImagePageHeader,
} from '../../shared/components';
import { FlightSearchWrap } from './search/FlightSearchWrap';
import { FlightSearch } from './search';
import { Shortcuts } from './shortcuts';
import { Sections } from '../../content-sections/ui';
import { useNavigate } from '../../shared/router/useNavigate';
import { useUrlForPageId } from '../../app/pages/ui/utils/useUrlForPageId';
import { PassengersPageId } from '../../app/pages/domain/constants/PageId';
import { IHomePageData } from '../domain/models/IHomePageData';
import { useSetFlightsEnrichmentData } from '../../flights/shared/flight/ui/useSetFlightsEnrichmentData';
import { GuestBanner } from './GuestBanner';
import { useAuthStore } from '../../auth/shared/store/useAuthStore';
import { RewardOffers } from '../../account/ui/account-offers/ui/RewardOffers';

export interface IHomePageProps extends IHomePageData {}

export const HomePage = observer(
	({ page, headerImages, flightsEnrichmentData }: IHomePageProps) => {
		const navigate = useNavigate();
		const { isAuthenticated } = useAuthStore();
		const flightsUrl = useUrlForPageId(PassengersPageId.Flights);

		const navigateToFlightList = () => {
			navigate(flightsUrl);
		};

		useSetFlightsEnrichmentData(flightsEnrichmentData);

		return (
			<PageLayout page={page}>
				<StyledHomePage>
					<ImagePageHeader page={page} images={headerImages} />

					<FlightSearch navigateToFlightList={navigateToFlightList} />

					<Shortcuts isAuthenticated={isAuthenticated} />

					<GuestBanner />

					<RewardOffers hasHeader />

					{page.fields.sections && <Sections sections={page.fields.sections} />}
				</StyledHomePage>
			</PageLayout>
		);
	},
);

const FLIGHT_SEARCH_OVERLAP_DESKTOP = '150px';
const FLIGHT_SEARCH_OVERLAP_MOBILE = '40px';

const StyledHomePage = styled.div`
	${getOverlapWithImagePageHeaderStyles(
		FlightSearchWrap,
		FLIGHT_SEARCH_OVERLAP_DESKTOP,
		FLIGHT_SEARCH_OVERLAP_MOBILE,
	)}
`;
