import styled from '@emotion/styled';
import { DropdownItemHint, ITheme, Label } from '../../../../ui';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';

export const FlightSearchHighlight = styled.div<{}, ITheme>`
	max-width: 600px;
	background-color: #ffffff;
	padding: 16px 24px 24px;
	margin: 0 0 20px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
	background: ${themed(({ color }) => color.background.default)};
	border-radius: 8px;

	h2 {
		padding-bottom: 5px;
	}

	${Label} {
		position: initial;
	}

	${DropdownItemHint} {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		max-width: 100%;
		margin: 0 0 10px;

		h2 {
			margin-bottom: 0px;
			border: none;
		}

		${DropdownItemHint} {
			display: block;
		}
	}
`;
