import { ILocale } from '../../../../shared/i18n/locale/models/ILocale';
import { LocaleCode } from '../../../../shared/i18n/locale/models/LocaleCode';

const LOCUS_LABS_MAP_URL = 'https://maps.cltairport.com/';

interface ILocusLabsLanguages {
	code: string;
	urlParam?: string;
}

const locusLabsLanguages: Record<LocaleCode, ILocusLabsLanguages> = {
	'en-US': {
		code: 'en',
		urlParam: undefined,
	},
	'es-US': {
		code: 'es',
		urlParam: 'vid=cltes',
	},
};

export function getMapUrlForPoiId(currentLocale: ILocale, poiId?: string) {
	const locusLabsLanguage = locusLabsLanguages[currentLocale.code];
	const baseUrl = `${LOCUS_LABS_MAP_URL}?lang=${locusLabsLanguage.code}&poiId=${poiId}`;

	if (locusLabsLanguage.urlParam) {
		return `${baseUrl}&${locusLabsLanguage.urlParam}`;
	}

	return baseUrl;
}
